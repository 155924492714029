<!-- eslint-disable @angular-eslint/template/button-has-type -->
<div class="error-page">
  <img src="assets/illustrations/error-illustration.svg" alt="Chat" i18n-alt />
  <h6 i18n>
    An unexpected error occurred. Please try again or go back to the dashboard.
  </h6>

  <button
    i18n
    mat-button
    class="primary-full-lg-button"
    (click)="navigateToDashboard()"
  >
    Go to Daily Look
  </button>
</div>
